import fetch from 'cross-fetch';
import config from '@config';
import TokenManager from '@xFrame4/business/TokenManager';

export class GraphQlClient
{
    private client: any;

    constructor() 
    {
        this.client = null;
    }

    /**
     * Dynamically load Apollo Client and initialize it.
     */
    private async loadClient() {
        if (!this.client) {
            const { ApolloClient, InMemoryCache } = await import('@apollo/client');
            const { createUploadLink } = await import('apollo-upload-client');
            
            // Define default options to disable cache.
            const defaultOptions = {
                watchQuery: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'ignore',
                },
                query: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
            };

            // Initialize the Apollo Client instance
            this.client = new ApolloClient({
                link: createUploadLink({ uri: config.apiUrl, fetch }),
                cache: new InMemoryCache(),
                defaultOptions: defaultOptions as any
            });
        }
    }

    /**
     * Prepare the context for a query.
     * Essentially, this function adds the Authorization header to the context.
     */
    private async prepareContextForQuery()
    {
        // Try to get current user token
        let tokenManager = new TokenManager(); 
        let token: string | null = null;
        if (typeof localStorage != 'undefined')
        {
            token = await tokenManager.getTokenFromLocalStorage() as string;
        }

        // Add Authorization headers to the context
        if (token != null)
        {
            return {
                headers:
                {
                    Authorization: 'JWT ' + token
                }
            };
        }
        else
        {
            return {};
        }
    }

    /**
     * Make a query.
     * 
     * @param options Has to include the following fields:
     * - query: The GraphQL query to make.
     * - variables: The variables to pass to the query (optional).
     */
    async query(options: any) 
    {
        await this.loadClient();

        // Add Authorization headers to the context
        options.context = await this.prepareContextForQuery();

        // Make the query
        try
        {
            let result = await this.client.query(options);
            
            if (result.errors != null && result.errors.length > 0)
            {
                let error = Error('GraphQL query error: ' + result.errors[0].message);
                throw error;
            }

            return result;
        }
        catch (error)
        {
            let handledError = this.handleGraphQlError(error);
            throw handledError;
        }
    }

    /**
     * Make a mutation.
     * 
     * @param options Has to include the following fields:
     * - mutation: The GraphQL mutation to make.
     * - variables: The variables to pass to the mutation (optional).
     */
    async mutate(options: any)
    {
        await this.loadClient();

        // Add Authorization headers to the context
        options.context = await this.prepareContextForQuery();

        // Make the mutation
        try
        {
            let result = await this.client.mutate(options);
            
            if (result.errors != null && result.errors.length > 0)
            {
                let error = Error('GraphQL mutation error: ' + result.errors[0].message);
                throw error;
            }

            return result;
        }
        catch (error)
        {
            let handledError = this.handleGraphQlError(error);
            throw handledError;
        }
    }

    /**
     * Handle the error returned from a GraphQL query or mutation.
     */
    handleGraphQlError(error: any)
    {
        // Check if the error is a network error:
        // Reason 1: the JSON is not valid (probably because a warning/error was returned from the server)
        // Reason 2: the JSON is valid, but the server returned an error
        let networkError = (error as any).networkError;
        if (networkError != null)
        {
            // console.log(networkError);
            let message = networkError.message;
            if (networkError.bodyText !== undefined)
            {
                message += '\n\n' + networkError.bodyText;
            }
            else if (networkError.result !== undefined && networkError.result.errors !== undefined)
            {
                if (networkError.result.errors.length > 0 && networkError.result.errors[0].message !== undefined)
                {
                    message += '\n\n' + networkError.result.errors[0].message;
                }
            }
            
            return new Error('Network error: ' + message);
        }
        else if (error.message !== undefined)
        {
            return new Error(error.message);
        }
        else
        {
            // console.log(error);
            return error;
        }
    }
}

// Create client.
export const client = new GraphQlClient();

export default client;