import { FunctionComponent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { getDataSourceDisplayMemberValue } from './FieldEditor';

export enum CheckboxListLayout
{
    Vertical,
    Horizontal
}

interface CheckboxListProps
{
    dataSource: { [index: string]: any }[];
    dataSourceDisplayMember?: string;
    dataSourceValueMember?: string;
    layout?: CheckboxListLayout
    disabled?: boolean;
    value?: { [index: string]: any }[];
    onChange?: (selectedItems: { [index: string]: any }[]) => void;
}

const CheckboxList: FunctionComponent<CheckboxListProps> = (props) =>
{
    const [checkedItems, setCheckedItems] = useState<{ [index: string]: any }[]>(props.value as []);
    if (props.value !== undefined && props.value != checkedItems) setCheckedItems(props.value); // hack: forcing 'checked' to be the value


    /** Handle the checkbox toggle for an item. */
    const handleToggle = (item: { [index: string]: any }) =>
    {
        if (props.disabled) return;

        // Update checked items
        const currentIndex = indexOfItemInList(checkedItems, item);
        const newCheckedItems = [...checkedItems];

        if (currentIndex === -1)
        {
            newCheckedItems.push(item);
        } else
        {
            newCheckedItems.splice(currentIndex, 1);
        }

        setCheckedItems(newCheckedItems);

        // Fire the change event
        if (props.onChange) props.onChange(newCheckedItems);
    };

    /** Find the index of an item in the list. */
    const indexOfItemInList = (list: { [index: string]: any }[], item: { [index: string]: any }) => 
    {
        return list.findIndex(i => i[props.dataSourceValueMember!] == item[props.dataSourceValueMember!]);
    };

    /** Checkboxes */
    let cmpCheckboxes = props.dataSource.map((item, i) => 
        <Form.Check 
            key={i}
            type="checkbox"
            label={getDataSourceDisplayMemberValue(item, props.dataSourceDisplayMember as string)}
            checked={checkedItems.some(ci => ci[props.dataSourceValueMember as string] == item[props.dataSourceValueMember as string])}
            className="me-2"
            onChange={() => handleToggle(item) }
        />
    );

    /** Layout */
    let classes = '';
    if (props.layout == CheckboxListLayout.Vertical)
    {
        classes = '';
    }
    else
    {
        classes = 'd-flex'
    }

    /** Render */
    return (
        <div 
            className={'checkbox-list border p-1 ' + classes}
            style={{ maxHeight: '10rem', overflow: 'auto' }}
        >
            {cmpCheckboxes}
        </div>
    );
}

CheckboxList.defaultProps =
{
    dataSourceValueMember: 'value',
    dataSourceDisplayMember: 'display',
    layout: CheckboxListLayout.Vertical,
    value: []
}

export default CheckboxList;