import React, { useEffect } from 'react';
import { FunctionComponent } from 'react';
import { Navigate} from 'react-router-dom';
import { useTranslate } from '@xFrame4/components/Hooks';
import { useAuthUserLoginFromJWT } from '@xFrame4/components/Hooks';
import Loader from '@components/hyper/components/Loader';
import AdminLayout from '@components/layout/AdminLayout';

interface AppProps
{

}

const App: FunctionComponent<AppProps> = () =>
{
    const t = useTranslate();
    const { isLoading, isUserLoggedIn, hasAuthRequestFinished } = useAuthUserLoginFromJWT([]);
    
    // set document title
    useEffect(() =>
    {
        document.title = t('APP_TITLE');
    }, []);
    
    return (
        <>
            {isLoading && <Loader />}
            {!isLoading && !isUserLoggedIn && <Navigate to="/login" replace={true} />}
            {!isLoading && isUserLoggedIn && <AdminLayout />}
        </>
    );
}

export default App;